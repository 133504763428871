<template>
  <v-row class="d-flex justify-center mt-n16">
    <v-col cols="12" md="5">
      <v-form>
        <v-card class="card-shadow bg-secondary">
          <v-card-text class="pa-12 text-center font-size-root">
            <small class="text-muted">
              Enter your email address to reset your password
            </small>

            <v-text-field
              v-on:keyup.enter="resetPassword"
              v-model="loginDetails.username"
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              placeholder="Email Address"
              class="font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon mt-6 mb-4"
              :error-messages="
                getFieldValidationErrors(v$.loginDetails.username.$errors)
              "
              @blur="v$.loginDetails.username.$touch"
            >
              <template slot="prepend-inner">
                <v-icon color="#adb5bd" size=".875rem">ni-email-83</v-icon>
              </template>
            </v-text-field>

            <v-btn
              v-on:keyup.enter="resetPassword"
              @click="resetPassword"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm my-6"
              color="#5e72e4"
              :loading="submitButton.isLoading"
              >Reset Password</v-btn
            >
          </v-card-text>
        </v-card>
      </v-form>

      <div class="d-flex justify-space-between mt-4">
        <p
          href="javascript:;"
          class="text-light text-decoration-none btn-hover no-default-hover"
          style="cursor: pointer"
        >
          <small>Forgot password?</small>
        </p>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "login",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      loginDetails: {
        username: { required },
      },
    };
  },
  data() {
    return {
      loginDetails: {
        username: "",
      },
      submitButton: {
        isLoading: false,
      },
    };
  },
  computed: {
    ...mapStores(useSessionStore),
  },
  methods: {
    getFieldValidationErrors(errors) {
      const messages = [];

      for (let i = 0; i < errors.length; i++) {
        messages.push(`${errors[i].$message}`);
      }

      return messages;
    },

    async resetPassword() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        this.$notify.warning(
          "Please resolve the validation errors before proceeding!"
        );
        return;
      }

      this.submitButton.isLoading = true;

      const loginObj = {
        email: this.loginDetails.username,
      };

      this.$http
        .post("/forgot-password", loginObj)
        .then(async () => {
          this.$notify.info("Please check your email for instructions to reset your password.");
          this.$router.push("/login");
        })
        .catch((error) => {
          console.error("Login error: ", error);
          if ([401, 422].includes(error.response.status)) {
            this.$notify.error(error.response.data.message);
          } else {
            this.$notify.error(
              "An error occurred while loging in. Please try again later or contact support."
            );
          }
        })
        .finally(() => {
          this.submitButton.isLoading = false;
        });
    },
  },
  mounted() {
    this.loadAppConfig();
  },
};
</script>
